import React, {Component} from 'react';
import PropTypes from "prop-types";
import Image from '../../Image';
import { COMPANY_NAME } from '../../../utils/config';
import {ImageUrl} from '../../../utils/constants';
import {Tab, Modal} from "react-bootstrap";
import { isEmpty } from 'lodash';
import { StyledModal, StyledBody, StyledTabContainer, Rectangle, EmployeeList, Employee, ImageContainerPopUp, NameWellnessContainer, WellnessInterest, EmptyElement } from './styles';

class ParticipantsPopUp extends Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1
    }
  }
  setActiveTab = (active) => {
    this.setState({selectedTab: active})
  }
  render() {
    const { showModal, onClose, challengeAttendees, amigosList } = this.props;
    return(
      <StyledModal
        show={showModal}
        onHide={onClose}
      >
        <Modal.Header closeButton />
        <Rectangle>
          <StyledBody>
            <StyledTabContainer defaultActiveKey={1} id="styledTabs"  onSelect={this.setActiveTab} fontSize="16px">
              <Tab eventKey={1} title="Participants" tabClassName="first-tab">
                {!isEmpty(challengeAttendees) && challengeAttendees.length > 0 ?
                  <EmployeeList>
                    {challengeAttendees.map((user, i) => (
                      <Employee key={i}>
                        <ImageContainerPopUp>
                          <Image image={user.profile_image} />
                        </ImageContainerPopUp>
                        <NameWellnessContainer>
                          <p>{user.fname.concat("  ",user.lname)}</p>
                          <WellnessInterest key={i}>
                            {user.interests.slice(0, 5).map((interest, k) => (
                              <div key={k}>
                                <img src={`${ImageUrl}/${interest.interest_image}`} />
                              </div>
                            ))}
                          </WellnessInterest>
                        </NameWellnessContainer>
                      </Employee>
                    ))
                    }
                  </EmployeeList>
                  :
                  <EmployeeList display="flex" alignItems="center">
                    <EmptyElement><p>No Data Available</p></EmptyElement>
                  </EmployeeList>
                }
              </Tab>
              <Tab eventKey={2} title={COMPANY_NAME=== 'Woliba'? 'Buddies' : 'Amigos'} tabClassName="second-tab">
                {!isEmpty(amigosList) && amigosList[0].length > 0 ?
                  <EmployeeList>
                    {amigosList.map((amigo) => (amigo.map((user, i) =>(
                      <Employee key={i}>
                        <ImageContainerPopUp>
                          <Image image={user.profile_image} />
                        </ImageContainerPopUp>
                        <NameWellnessContainer>
                          <p>{user.fname.concat("  ",user.lname)}</p>
                          <WellnessInterest key={i}>
                            {user.interests.slice(0,5).map((interest, k) => (
                              <div key={k}>
                                <img src={`${ImageUrl}/${interest.interest_image}`} />
                              </div>
                            ))}
                          </WellnessInterest>
                        </NameWellnessContainer>
                      </Employee>
                    ))
                    )) 
                    }
                  </EmployeeList>
                  :
                  <EmployeeList display="flex" alignItems="center">
                    <EmptyElement><p>No Data Available</p></EmptyElement>
                  </EmployeeList>
                }
              </Tab>
            </StyledTabContainer>
          </StyledBody>
        </Rectangle>
      </StyledModal>
    );
  }
}
ParticipantsPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  challengeAttendees: PropTypes.array,
  amigosList: PropTypes.array,
};
export default ParticipantsPopUp;
