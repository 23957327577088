/* eslint-disable no-console,no-undef,camelcase */
import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  commaSeperator,
  convertDateInTimezone,
  getStatus,
  getTimeToShow,
  getLastSyncDetails,
  rankingDashboard,
  convertMilesToKm,
  convertMonthsWeeksText
} from "../../utils/methods";
import moment from 'moment';
import { connect } from "react-redux";
import Image from '../Image';
import isEmpty from 'lodash/isEmpty';
import Waiting from '../Waiting';
import { joinOrLeaveChallenge, getInviteAmigosList } from "../../redux/actions/challengeActions";
import { MyTaskContainerV2 } from './MyTaskTab/styles';
import { EventsButton, ButtonContainer, TableContainer, TableHeading, TableData, CompletePointCard } from './styles';
import { AboutContainer } from '../EventDetails/styles';
import { PositionCard, LastSync, ParticipantCard } from './OverViewScreen/styles';
import { Border } from '../EventDetails/styles';
import { ImageUrl } from '../../utils/constants';
import isNull from 'lodash/isNull';
const InviteAmigoPopUp = lazy(() => import("./InviteAmigoPopUp/inviteChallengePopup"));
import { withTranslation } from 'react-i18next';
import parse from 'react-html-parser';
// import LazyImage from '../common/LazyImage/LazyImage';
class SurvivorRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
      isButtonDisabled: false
    }
  }
  componentDidMount() {
    const { challenge } = this.props;
    // getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);

  }
  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    this.setState({ isButtonDisabled: true });
    window.setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 2000);
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };

  render() {
    
    const { challenge, userId, syncTime,  t, challengeAttendees } = this.props;
    const { time, isButtonDisabled } = this.state;
    if ((!(this.props.userChallengeDetails) || !userId)) {
      return <Waiting />
    }
    let propsChallengeCompetitors = [];
    if (challenge.challenge_type === 'new_group') {
      const { user_details: userDetails } = this.props.userChallengeDetails;
      propsChallengeCompetitors = userDetails;

    } else {
      propsChallengeCompetitors = this.props.userChallengeDetails['user_details'];
    }
    let challengeCompetitors = {};
    let challengeOver = true;
    time.map((step) => {
      const values = step.split(' ');
      if (values && parseInt(values[0],10) > 0) {
        challengeOver = false;
      }
    });
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    const joinOrLeave = (challenge.id !== 53) ? !challengeOver && (challenge.challenge_status === 'joined' ? 0 : 1) : (challenge.challenge_status === 'joined' ? 0 : 1);
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    else if (this.props.userChallengeDetails) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    const syncUpdate = getLastSyncDetails(syncTime);
    if (_.isUndefined(challengeCompetitors)) {
      return <Waiting />
    }

    const { userChallengeDetails } = this.props;
    if (!userChallengeDetails || Object.keys(userChallengeDetails).length === 0) {
      return <Waiting />
    }
    let weekArr = [];
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    for (let i = 0; i < challenge.weeks+1; i++) {
      weekArr.push(i);
    }
    const DistanceUnit = localStorage.getItem('DISTANCE');
    let weeklyGoal= challenge && challenge.week_goal;
    let weeklyAverage=challenge && challenge.week_total;
    return (
      <MyTaskContainerV2>
        {challenge.challenge_status == 'over' && !isEmpty(challengeCompetitors) && challengeCompetitors?
          <CompletePointCard>
            <div className='width'>{"You recorded a whopping"}&nbsp;<div className='value'>{challenge.event_type === 'Total Steps' ?`${commaSeperator(challengeCompetitors['steps'])} Steps`:challenge.event_type === 'Total Calories'?`${commaSeperator(challengeCompetitors['calories'])} calories` :`${commaSeperator(challengeCompetitors['distance'])} Miles`}</div></div>
            <div className='width'>{"and survived"}&nbsp; <div className='value'>{challenge && challenge.weeks+1} {"weeks earning"}&nbsp;{challenge.challenge_point} Points!</div></div>
          </CompletePointCard>:null}
        {challenge.challenge_status === 'joined' && !isEmpty(challengeCompetitors) && challengeCompetitors && challenge.challenge_status === 'joined' &&
          <PositionCard color={'#005C87'}valueColor={challenge.event_type === 'Total Steps'?"#76AB78":challenge.event_type === 'Total Calories'?"#FFBF73":"#85C0EA"} width="1">
            <div>
              <img src={`${ImageUrl}/${challengeCompetitors['profile_image']}`} className="profile" />
            </div>
            <div>
              <div>
                <div className="attendies">{t("Overall Position")}
                </div>
                {isNull(challengeCompetitors['user_rank']) ? <div className="rankcolor">0</div> :
                  <div className="rankcolor">{challengeCompetitors['user_rank']}{rankingDashboard(challengeCompetitors['user_rank'] - 1)} {t("Rank")}</div>}
              </div>
              <Border margin={"auto 7px auto 15px"}/>
              <div>
                {weeklyAverage<weeklyGoal?
                  <div>{<span>{challengeCompetitors && challengeCompetitors.weekly_details && challenge.event_type === 'Total Distance'?DistanceUnit === "KILOMETER"? commaSeperator(convertMilesToKm(weeklyGoal-weeklyAverage)) : commaSeperator(weeklyGoal-weeklyAverage) : commaSeperator(weeklyGoal-weeklyAverage)} </span>}
                    <p style={{ color: challenge.event_type === 'Total Steps'? "#76AB78": challenge.event_type === 'Total Calories'?'#FFBF73':'#85C0EA'}}>&nbsp;
                      {challenge.event_type === 'Total Steps'?t("Steps"):challenge.event_type === 'Total Calories'?t("Calories"):DistanceUnit === "KILOMETER"?t("Kilometer"):t("Mile")}
                    </p>&nbsp;
                    {t("Needed in week")}&nbsp;{challengeCompetitors && challengeCompetitors.weekly_details && challengeCompetitors.weekly_details.length}</div>:
                  <div>{t("Goal Achieved")}</div>
                }
                <div />
              </div>
            </div>
          </PositionCard>}
        {!isNull(challengeCompetitors) && !isNull(challengeCompetitors['weekly_details'])&& <TableContainer>
          <TableHeading>
            <div />
            <div>{t("Weeks")}</div>
            <div>{t("Weekly Goal")}</div>
            <div>{t("Achieve")}</div>
          </TableHeading>
          {isNull(challengeCompetitors) || isNull(challengeCompetitors['weekly_details']) ? null :
            weekArr.map((week, index) =>
              ( !_.isUndefined(challengeCompetitors['weekly_details']) && !isNull(challengeCompetitors['weekly_details']) && !isEmpty(challengeCompetitors) && challengeCompetitors['weekly_details'].length - 1 >= index ?
                <TableData key={index} color={challengeCompetitors['weekly_details'][index]['weekly_avg'] >= challengeCompetitors['weekly_details'][index]['weekly_goal'] ? '#85C0EA' : '#F4AAA9'} background={challengeCompetitors['weekly_details'][index]['weekly_avg'] >= challengeCompetitors['weekly_details'][index]['weekly_goal'] ? '#005C870D' : '#F4AAA933'} border={challengeCompetitors['weekly_details'][index]['weekly_avg'] >= challengeCompetitors['weekly_details'][index]['weekly_goal'] ? "4px solid #85C0EA" : "4px solid #F4AAA9"}>
                  <div><div className='circleBorder'>{challengeCompetitors['weekly_details'][index]['weekly_avg'] >= challengeCompetitors['weekly_details'][index]['weekly_goal'] ? <img src={ImageUrl+"/ChallengeDetailsScreen/completeCheck.png"} alt="right-arrow" /> : <img src={ImageUrl+"/ChallengeDetailsScreen/incompleteCheck.png"} alt="cross" />}</div></div>
                  <div>{index + 1}</div>
                  <div>{challengeCompetitors['survivor_average'] + (challengeCompetitors['weekly_increment'] * index)}</div>
                  <div>{commaSeperator(challengeCompetitors['weekly_details'][index]['weekly_avg'])}</div>
                </TableData> :
                <TableData key={index}>
                  <div><div className='circleBorder'></div></div>
                  <div>{index + 1}</div>
                  <div>{challengeCompetitors['survivor_average'] + (challengeCompetitors['weekly_increment'] * index)}</div>
                  <div>{"-"}</div>
                </TableData>
              )
            )
          }
        </TableContainer>}
        {isNull(challengeCompetitors) || isNull(challengeCompetitors['weekly_details']) ? null :
          challenge.challenge_status === 'joined' && !_.isUndefined(challengeCompetitors['weekly_details']) && !isNull(challengeCompetitors['weekly_details']) && !isEmpty(challengeCompetitors) && (challenge.event_type === 'Total Steps' && challenge.challenge_status === 'joined' && !(today < startDay) ?
            <PositionCard color={'#76AB78'}>
              <div>
                <div className='img'>
                  <img src={ImageUrl+"/ChallengeDetailsScreen/Steps.svg"} alt="back-arrow" />
                </div>
              </div>
              <div>
                <div>
                  <div className="attendies">{t("My Total Steps")}</div>
                  {challengeCompetitors['steps'] !== null ? <div>{commaSeperator(challengeCompetitors['steps'])}
                  </div> : <div>0</div>}
                </div>
                <Border margin={"auto 15px auto 15px"}/>
                <div>
                  <div>{t("My Daily Average")}</div>
                  <div>{commaSeperator(challengeCompetitors['daily_average'])}</div>
                </div>
              </div>
            </PositionCard> :
            challenge.event_type === 'Total Calories' && challenge.challenge_status === 'joined' && !(today < startDay) && !_.isUndefined(challengeCompetitors['weekly_details']) && !isNull(challengeCompetitors['weekly_details']) && !isEmpty(challengeCompetitors) ?
              <PositionCard color={'#FFBF73'}>
                <div>
                  <div className='img'>
                    <img src={ImageUrl+"/ChallengeDetailsScreen/Calories.svg"} alt="back-arrow" />
                  </div>
                </div>
                <div>
                  <div>
                    <div className="attendies">{t("My Total Calories")}</div>
                    {challengeCompetitors['calories'] !== null ? <div>{commaSeperator(challengeCompetitors['calories'])}
                    </div> : <div>0</div>}
                  </div>
                  <Border margin={"auto 15px auto 15px"}/>
                  <div>
                    <div>{t("My Daily Average")}</div>
                    <div>{commaSeperator(challengeCompetitors['daily_average'])}</div>
                  </div>
                </div>
              </PositionCard> :
              challenge.challenge_status === 'joined' && !(today < startDay) && !_.isUndefined(challengeCompetitors['weekly_details']) && !isNull(challengeCompetitors['weekly_details']) && !isEmpty(challengeCompetitors) && <PositionCard color={'#85C0EA'}>
                <div>
                  <div className='img'>
                    <img src={ImageUrl + "/ChallengeDetailsScreen/Distance.svg"}/>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="attendies">{t("My Total Distance")}</div>
                    {challengeCompetitors['distance'] !== null ? <div>{commaSeperator(challengeCompetitors['distance'])}
                    </div> : <div>0</div>}
                  </div>
                  <Border margin={"auto 15px auto 15px"}/>
                  <div>
                    <div>{t("My Daily Average")}</div>
                    <div>{commaSeperator(challengeCompetitors['daily_average'])}</div>
                  </div>
                </div>
              </PositionCard>)}
        {(challenge.challenge_status === 'joined'||challenge.challenge_status === 'over')&&<ParticipantCard >
          <div className='image'>
            <img src={ImageUrl + "/images/NewDashboardV2/EventsAttendies.png"}/>
          </div>
          <div className='text'>{this.props.t("Participants")}</div>
          <div className='count'>{challengeAttendees && challengeAttendees.length}</div>
        </ParticipantCard>}
        {challenge.challenge_status === 'joined' && syncUpdate.length>0 &&<LastSync>
          <div>{t("Last Sync")}</div>
          {
            !(today < startDay)
              ? 
              <div>
                {
                  syncUpdate.length >= 2 
                    ? ( localStorage.getItem("lang") !='fr' ? `${syncUpdate[0]} ${syncUpdate[1]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)} ${convertMonthsWeeksText(syncUpdate[1], this.props.t)} ` )
                    : ( localStorage.getItem("lang") !='fr' ? `${syncUpdate[0]} ${this.props.t("ago")}` : `${this.props.t("ago")} ${convertMonthsWeeksText(syncUpdate[0], this.props.t)}`)}
              </div>
              :<div>-</div>}
        </LastSync>}
        {challenge.challenge_type == 'survivor' && challenge.challenge_status !== 'over' ?
          <ButtonContainer >
            {challenge.is_joined === 1 ?null:
              <EventsButton backgroundColor={"#005C87"} color={"white"} onClick={isButtonDisabled?null:() => this.joinChallenge(challenge.id, joinOrLeave)} padding={'15px 8px 15px 8px'} font={"18px"} margin={"25px"} boxShadow="0px 8px 24px 0px #005C8780">
                {t("Join Challenge")}
              </EventsButton>}
            <AboutContainer marginTop={'0px 0px 15px 0px'} color={"#005C87"} colorValue="#005C87">
              <div>{t("About The Challenge")}</div>
              <div >{parse(challenge.body)}</div>
            </AboutContainer>{/*  */}
            {challenge.is_joined === 1 ?
              <EventsButton backgroundColor={"#005C870D"} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"} margin={"25px"}>
                {t("You Joined This Challenge")}
              </EventsButton>:null} 
          </ButtonContainer> : null}
        {challenge && challenge.challenge_status === 'over' &&
            <EventsButton backgroundColor={'#F4AAA94D'} color={"#005C87"} padding={'15px 8px 15px 8px'} font={"18px"} cursor={"1"}>
              {t("Challenge Completed")}
            </EventsButton>}
        <Suspense fallback={<Waiting/>}><InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1} /></Suspense>
      </MyTaskContainerV2>
    )
  }
}
SurvivorRightSection.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  joinOrLeaveChallenge: PropTypes.func,
  syncTime: PropTypes.string,
  getInviteAmigosList: PropTypes.func,
  history: PropTypes.object,
  role: PropTypes.string,
  t: PropTypes.func,
  challengeAttendees: PropTypes.array
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  syncTime: state.challenges.syncTime,
  challengeAttendees: state.challenges.challengeAttendees
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SurvivorRightSection));
