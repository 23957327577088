import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import { StyledTabs } from '../../ChallengeDetailsV2/styles';
import { ImageContainer } from '../../PeopleListViewV2/styles';
const RegularFont = 'Rubik-Regular';
const StyledBody = styled(Modal.Body)`
  width: 100%;
  border-radius: 12px;
  @media (max-width: 500px) and (min-width: 320px) {
    margin-top: -30px;
  }
`;
const StyledModal = styled(Modal)`
  .modal-content{
    width: 100%;
    height: 590px;
    border-radius: 10px;
    .modal-header {
      border-bottom: 0px;
    }
    .modal-header {
      background-color: rgb(71, 152, 217);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .modal-header .close {
      margin-top: -2px;
      outline: none;
    }
  }
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-content{
      width: 100%;
      margin-left: -2%;
    }
    .modal-dialog {
      margin-left: 24px;
    }
  }  
`;
const Rectangle = styled.div`
  width: 100%;
  height: 200px;
  background-color: rgb(71, 152, 217);
  background-size: 100% 250px;
  border-top: 10px;
`; 

const StyledTabContainer = styled(StyledTabs)`
  float: none;
  margin-top: 16px;
  >ul {
    margin-left: 60px;
    width: 80%;
    display: flex;
  }
  > ul > li > a {
    font-size: ${({fontSize}) => fontSize ? fontSize : '15px' };
  }
  > ul > li {
    margin-left: 35px;
  }

  @media (max-width: 500px) and (min-width: 320px) {
    margin-top: 10px;
    width: 125%;
    >ul {
      margin-left: -2%;
      width: 84%;
    }
  }
  @media (max-width: 600px) {
    > ul {
      min-height: 50px;
      margin-top: 30px;
      padding: 4px 0px;
      display: flex;
    }
    > ul > li > a {
      font-size: 15px;
      margin-left: 20px;
      padding: 5px 5px 10px;
    }
  }
  @media (max-width: 325px) {
    > ul > li > a {
      font-size: 15px;
      margin-left: -10px;
      padding: 5px 5px 10px;
    }
  }
`;
const EmployeeList = styled.div`
  margin-top: 20px;
  background-color: white;
  padding: 1px;
  width: 80%;
  background-color: white;
  border-radius: 6px;
	margin-left: 60px;
	display: ${({display})  => display ? display : 'block'}
	align-items: ${({alignItems})  => alignItems};
	max-height: 400px;
	min-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }

    ::-webkit-scrollbar-thumb {
     background: #c7c8c7;
     cursor: pointer;
     border-radius: 2.5px;
   }

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }
   @media (max-width: 500px) and (min-width: 320px) {
    {
      width: 84%;
      margin-left: -2%;
    }
`
;
const Employee = styled.div`
  padding: 5px;
  width: 100%;
  height: 80px;
  left: 790px;
  top: 807px;
  border-bottom: 1px solid #edebeb;
  
  @media (max-width: 500px) and (min-width: 320px) {
    {
      width: 100%;
    }
`
;
const ImageContainerPopUp = styled(ImageContainer)`
  left: 4.66%;
  right: 81.34%;
  top: 20%;
  bottom: 20%;
  background-blend-mode: normal;
  width: 50px;
  height: 50px;
  border-radius: 0;
  background-color: none;
  margin-right: 0px;

  > img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  @media (max-width: 500px) and (min-width: 320px) {
    width: 45px;
    height: 45px;
    float: left;
  }
`
;
const NameWellnessContainer = styled.div`
  width: 290px;
  height: 100%;
  margin-left: 90px;
  padding: 3px;

  > p{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    width: 100%;
    line-height: 24px;
    color: rgb(40, 44, 55);
    margin-bottom: 0;
  }

   @media (max-width: 500px) and (min-width: 320px) {
    {
      margin-left: 60px;
      height: 60px;
      width: 200px;
      p {
        font-size: 13px;
        margin-top: 0px;
        margin-left: 4px;
      }
    }
`
;
const WellnessInterest = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0px;

  > div{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 5px;
    background-color: rgb(246, 246, 248);
  }

  > div > img{
    width: 60%;
    height: 60%;
    border-radius: 50%;
    opacity: 0.7;
  }

  @media (max-width: 500px) and (min-width: 320px) {
    {
      display: flex;
      flex-flow: row;
      
      div {
        margin-bottom: 5px;
      }

      img {
        width: 10px;
        height: 10px;
      }
    }
`
;
const EmptyElement = styled.div`
	color: gray;
	font-size: 20px;
	height: 50px;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	font-family: ${RegularFont};

`;
export { StyledModal, StyledBody, StyledTabContainer,
  StyledTabs, EmployeeList, Employee,
  ImageContainerPopUp, NameWellnessContainer, WellnessInterest,
  Rectangle, EmptyElement

}